<template>
	<div class="ourHonor-box">
        <!-- header组件     -->
		<vheader
        :navId="navId">
		</vheader>
        <!-- header组件     -->
		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<!-- banner组件 -->
		<div class="ourHonor-content">			       
			<div class="title05">
				<h1>OUR HONOR</h1>
				<h2>公司荣誉</h2>
			</div>
			<div class="box01">
				<div class="top01">
					<p>
						北京恒安康泰医疗健康科技有限公司（简称“恒安康泰医疗健康科技”，英文全称“HenganMedicare”）是一家中型医疗咨询服务及健康管理公司，在2020年突发新型冠状肺炎疫情的大背景下，我公司经过股份整合，成立北京恒安康泰医疗健康科技有限公司，公司注册资本1000万。
					</p>
					<p>
						恒安康泰医疗健康科技，聚焦我国大众医疗重点领域，通过与北京医疗健康各合作方的高效连接，持续打造精准、细致、便捷的赴京就医新生态体系。致力于构建“赴京就医全方位医疗保障平台”，为全国各地的患者，尤其是企事业在职员工提供优质，全面，深入人心的赴京就医诊疗和健康管理服务。
					</p>
				</div>
				<div class="center01">
					<h1>恒安康泰集团</h1>
					<p>恒安康泰医疗健康科技，聚焦我国大众医疗重点领域，通过与北京医疗健康各合作方的高效连接，持续打造精准、细致、便捷的赴京就医新生态体系</p>
				</div>
				<div class="bottom01">
					<p>
						公司凭借国内领先的医疗信息管理、健康知识管理、疾病分析管理等行业经验，针对企事业单位职工个人用户，利用“互联网+”手段，构建“恒安康泰医疗一路通”APP，成为提供便捷赴京就医、个人医疗健康管理、企事业人私人医疗健康保障的综合服务线上平台，为企事业人及大众赴京看病就医、日常健康咨询管理等提供便利。
					</p>
					<p>
						恒安康泰医疗健康科技以智慧就医保障为核心，健康咨询和医疗行程服务为辅助，打造企事业人和大众的智慧就医一体化平台，全面赋能大众健康生活。同时，积极延伸至为卫生、医院、药企、养生、健康动态生活产业链赋能。此外，公司可为合作伙伴提供专业赴京医疗就诊咨询服务，特色医院推荐服务，并为企事业人和大众提供便捷、精准、安全、优质的创新型健康检测服务。
					</p>
				</div>
			</div>
			<ul class="box02">
				<li>
					<div class="div02">
						<img src="../../assets/img/ourHonor/oh01.jpg" alt="公司荣誉">
					</div>					
					<div class="div01">
						<p>公司荣誉</p>
						<p>恒安康泰是北京中关村海淀区科技创业园入园企业。</p>
					</div>					
				</li>
				<li>
					<div class="div01">
						<p>公司荣誉</p>
						<p>北京《中关村杂志》专访报道恒安康泰集团: 《对话恒安康泰医疗，让赴京医疗更方便》。</p>
					</div>						
					<div class="div02">
						<img src="../../assets/img/ourHonor/oh02.jpg" alt="公司荣誉">
					</div>				
				</li>
				<li>						
					<div class="div02">
						<img src="../../assets/img/ourHonor/oh03.png" alt="公司荣誉">
					</div>
					<div class="div01">
						<p>公司荣誉</p>
						<p>中央电视台品牌支持： CCTV “蓝海工程”“中国品牌故事”，CCTV“发现之旅”录制组邀请参与企业故事录制，节目在CCTV-1，CCTV经济，CCTV故事频道播出。</p>
					</div>				
				</li>
				<li>
					<div class="div01">
						<p>公司荣誉</p>
						<p>恒安康泰获评入围“北京2021创客大赛”并进入复赛。</p>
					</div>						
					<div class="div02">
						<img src="../../assets/img/ourHonor/oh04.png" alt="公司荣誉">
					</div>				
				</li>
				<li>					
					<div class="div02">
						<img src="../../assets/img/ourHonor/oh05.png" alt="公司荣誉">
					</div>
					<div class="div01">
						<p>公司荣誉</p>
						<p>恒安康泰获评北京中关村“创客小镇”入驻企业资格。</p>
					</div>					
				</li>
			</ul>
			<ul class="m-box02 mobile">
				<li v-for="item in mList" :key="item.index">
					<div class="div02">
						<img :src="item.imgUrl" alt="公司荣誉">
					</div>					
					<div class="div01">
						<p>{{item.word}}</p>
					</div>					
				</li>
			</ul>

			<div class="title05">
				<h1>CERTIFICATE</h1>
				<h2>荣誉证书</h2>
			</div>

			<div class="box07">	
			<el-carousel :interval="4000" type="card" height="275Px">
				<el-carousel-item v-for="item in box07List" :key="item.index">
					<img class="img07" :src="item.imgUrl" alt="公司荣誉">
				</el-carousel-item>
			</el-carousel>
			</div>
        </div>
        <!-- footer组件 -->
        <vfooter></vfooter>
        <!-- footer组件 -->
		<GoTop/>
    </div>
</template>

<script>
import banner from '../../components/banner.vue'
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'ourHonor',
	data () {
		return{
			navId:3,
			navName:'公司荣誉',			
			navUrl:'ourHonor', 			
			box07List:[
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
			],   
			mList:[
				{
					word:'恒安康泰是北京中关村海淀区科技创业园入园企业。',				
					imgUrl:require('../../assets/img/ourHonor/oh01.jpg')
				},
				{
					word:'北京《中关村杂志》专访报道恒安康泰集团: 《对话恒安康泰医疗，让赴京医疗更方便》。',				
					imgUrl:require('../../assets/img/ourHonor/oh02.jpg')				
				},
				{
					word:'中央电视台品牌支持： CCTV “蓝海工程”“中国品牌故事”，CCTV“发现之旅”录制组邀请参与企业故事录制，节目在CCTV-1，CCTV经济，CCTV故事频道播出。',				
					imgUrl:require('../../assets/img/ourHonor/oh03.png')					
				
				},
				{
					word:'恒安康泰获评入围“北京2021创客大赛”并进入复赛。',				
					imgUrl:require('../../assets/img/ourHonor/oh04.png')				
				},
				{
					word:'恒安康泰获评北京中关村“创客小镇”入驻企业资格。',				
					imgUrl:require('../../assets/img/ourHonor/oh05.png')				
				},
			]    
        }
	},
	props: {
	},
	methods:{		
		
	},
	components:{
		banner,
		vheader,
		vfooter,
		GoTop
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
	.mobile{
		display: none;
	}
    .ourHonor-box{
        .ourHonor-content{
            .title05{
            margin: AUTO;
            padding: 37Px 0 0Px;	
                :first-child{
                    font-size: 42Px;
                    color: #ddd;
                    text-align: center;
                }
                :nth-child(2){
                    width: 89Px;
                    margin:0 auto 30Px;
                    font-size: 22Px;
                    padding-bottom: 13Px;
                    color: #222;
                    border-bottom: 1Px solid #093C6C;
                }
            }
			.box01{
				width: 1200Px;
				margin:-10Px auto 0;
				color: #5a5a5a;
				margin-bottom: 35Px;
				font-size: 15Px;
				line-height: 35Px;
				.top01{
					p{
						margin-bottom: 10Px;
					}
				}
				.center01{
					width: 1100Px;
					margin: 25Px 0;
					padding: 120Px 50Px;
					color: #555;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: center;
					background: url(../../assets/img/ourHonor/oh00.png);
					h1{
						width: 1200Px;
						margin-bottom: 15Px;
						text-align: center;
						font-size: 25Px;
					}
					p{
					}
				}
				.bottom01{
					p{
						color: #888;
						margin-top: 15Px;
					}					
				}
			}
			.box02{
				width: 1200Px;
				margin: auto;
				border-top: 1Px solid #ccc;
				border-bottom: 1Px solid #ccc;
				li{
					display: flex;
					align-items: center;
					.div01{
						width: 500Px;
						text-align: center;
						color: #5a5a5a;
						padding: 0Px 50Px;
						:first-child{
							font-size: 18Px;

						}
						:nth-child(2){
							font-size: 14Px;
							margin-top: 15Px;
							line-height: 30Px;
						}
					}
					.div02{
						width: 50%;
						height: 355Px;
						overflow: hidden;
						img{
							width: 100%;
							height: 355Px;
							transition: all 0.2s;
							&:hover{
								transform: scale(1.1);
							}
						}
					}
				}
			}
			.box07{
				padding: 50Px 555Px 30Px;
			    background: url(../../assets/img/index/box06_01.jpg) no-repeat center / 100%;
			.img07{
				width: 400Px;
				height: 300Px;
			}
			/deep/.el-carousel__button{
				margin-top: -15Px;
				width: 40Px;
				height: 4Px;
			}		
			/deep/.el-carousel__arrow{
				width: 70Px;
				height: 70Px;
				font-size: 40Px;
				margin: 0 80Px;
			}
			/deep/.el-carousel__indicator--horizontal{
				padding: 40Px 10PX 0;
			}
		}
        }
    }
}

// 移动端样式
@media screen and (max-width:750px){
    .ourHonor-box{
        .ourHonor-content{
            .title05{
				width: 95%;
				margin: 16px auto 10px;
				:first-child{
					font-size: 25px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 65px;
					font-size: 16px;
					color: #222;
					margin:0 auto 12px;
					padding-bottom: 5px;
					border-bottom: 1px solid #093C6C;
				}
			}
			.box01{
				width: 95%;
				margin:0px auto 5px;
				color: #5a5a5a;
				font-size: 15px;
				line-height: 35px;
				.top01{
					width: 97%;
                    height: 142px;
                    overflow: auto;
                    margin: 10px 0 30px;
                    color: #666;
                    font-size: 14px;
                    line-height: 30px;
                    padding-right: 10px;
                    p{
                        margin-bottom: 10px;
                    }
                    &::-webkit-scrollbar {                
                        /*滚动条整体样式*/
                        width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
                    }
                    &::-webkit-scrollbar-thumb {
                        /*滚动条里面小方块*/
                        width: 10px;
                        border-radius: 10px;
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background   : #888;
                    }
                    &::-webkit-scrollbar-track {
                        /*滚动条里面轨道*/
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background   : #eee;
                    }  
				}
				.center01{
					width: 89.2%;
					margin: 25px 0;
					padding: 20px 20px;
					color: #555;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: center;
					background: url(../../assets/img/ourHonor/oh00.png) no-repeat center / 180%;
					h1{
						width: 95%;
						margin-bottom: 5px;
						text-align: center;
						font-size: 20px;
					}
					p{
						font-size: 13px;
						line-height: 25px;
						font-weight: 500;
					}
				}
				.bottom01{
					width: 97%;
                    height: 142px;
                    overflow: auto;
                    margin: 10px 0 30px;
                    color: #666;
                    font-size: 14px;
                    line-height: 30px;
                    padding-right: 10px;
                    p{
                        margin-bottom: 10px;
                    }
                    &::-webkit-scrollbar {                
                        /*滚动条整体样式*/
                        width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
                    }
                    &::-webkit-scrollbar-thumb {
                        /*滚动条里面小方块*/
                        width: 10px;
                        border-radius: 10px;
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background   : #888;
                    }
                    &::-webkit-scrollbar-track {
                        /*滚动条里面轨道*/
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background   : #eee;
                    }  				
				}
			}
			.box02{
				display: none;
			}
			.m-box02{
				width: 95%;
				margin:50px auto;
				li{
						border: 1px solid #ccc;
					margin-bottom: 15px;
					.div01{
						margin:0px auto 0;
						text-align: center;
						border-radius: 7px;
						color: #5a5a5a;
						padding:20px;
						z-index: 100;
						background-color: rgba(255,255,255,0.8);

						p{
							font-size: 14px;
							line-height: 25px;
						}
					}
					.div02{
						overflow: hidden;
						img{
							width: 100%;
						}
					}
				}
			}
			.box07{
				width: 95%;
				height: 200px;
				overflow: hidden;
				margin:30px auto -30px;				
				.img07{
					width: 180px;
				}
				/deep/.el-carousel__button{
					width: 45px;
					display: none;
					height: 5px;
				}		
				/deep/.el-carousel__arrow{
					display: none;
				}
				/deep/.el-carousel__indicator--horizontal{
					padding: 40px 10px 0;
				}
			}
        }
    }
}
</style>
